<form class="login-form" #loginForm="ngForm" (ngSubmit)="login()" novalidate>
    <div class="logo-container">
        <img class="logo" width="100%" height="100%" [src]="'/images/gui/encore-blue-logo.png'" alt="etis-logo">
    </div>

    <div class="login-error" *ngIf="errorMessage">
        <div id="login-error-title">Přihlášení se nezdařilo.</div>
        <div id="login-error-message">{{errorMessage}}</div>
    </div>

    <div class="login-in-progress" [hidden]="!isLoginInProgress">
        <span>Probíhá přihlašování</span>
    </div>

    <div [hidden]="isLoginInProgress">
        <div class="login-input" id="login-username" [hidden]="needs2FA">
            <i class="fa fa-user"></i>
            <input tabindex="1" #username required name="username" type="text"
                   placeholder="Uživatelské jméno" autocomplete="off" ngModel
                   [disabled]="loginDisabled" />
        </div>

        <div class="login-input" id="login-password" [hidden]="needs2FA">
            <i class="fa fa-lock"></i>
            <input tabindex="2" #password required name="password" type="password"
                   placeholder="Heslo" autocomplete="off" ngModel
                   [disabled]="loginDisabled" />
        </div>

        <div class="login-input" id="login-g2FA" [hidden]="!needs2FA">
            <img src="/images/gui/google-small-logo.png" class="login-google-logo">
            <input tabindex="1" #g2FA [required]="needs2FA" id="g2FA" name="g2FA" type="text"
                   placeholder="Autorizační kód" autocomplete="off"
                   class="login-google-2FA-code" ngModel
                   [disabled]="loginDisabled" (keyup)="g2FAChanged()" />
        </div>

        <button tabindex="3" type="submit" class="classic-button" id="login-main-btn" [disabled]="loginDisabled">Přihlásit se</button>

        <button id="login-other-btn" type="button"
                [ngClass]="otherLoginButton.style"
                *ngIf="otherLoginButton"
                [hidden]="needs2FA"
                [disabled]="loginDisabled"
                (click)="initiateOIDCAuthentication()"
        >
            {{otherLoginButton.text}}
        </button>
    </div>
</form>
