import { AuthConfig } from 'angular-oauth2-oidc';

// Default configuration for OIDC auth flow
export const oidcAuthCodeFlowConfig: AuthConfig = {
    responseType: 'code',
    showDebugInformation: true,
    timeoutFactor: 0.01,
    oidc: true,
    strictDiscoveryDocumentValidation: false,
    requestAccessToken: true
};
