import { Injectable } from '@angular/core';

import {AuthConfig, OAuthService} from "angular-oauth2-oidc";
import {oidcAuthCodeFlowConfig} from "../../../../../config/oidc-auth.config";

@Injectable()
export class OpenIDConnectAuthService {

    constructor(private oauthService: OAuthService) {}

    async configure(config: AuthConfig) {
        let oauthConfig = oidcAuthCodeFlowConfig;
        Object.assign(oauthConfig, config);

        this.oauthService.configure(oauthConfig);
    }

    isAuthenticated(): boolean {
        return this.oauthService.hasValidAccessToken();
    }

    hasAuthToken(): boolean {
        return this.oauthService.getAccessToken() != null;
    }

    getAuthToken(): string {
        return this.oauthService.getAccessToken();
    }

    async authenticate() {
        let authResult = await this.oauthService.tryLoginCodeFlow();
        if (authResult !== null && !this.oauthService.hasValidIdToken() || !this.oauthService.hasValidAccessToken()) {
            this.oauthService.initLoginFlow();
        }
    }

    async logout() {
        await this.oauthService.revokeTokenAndLogout();
    }

    async removeTokens(noRedirectToLogin?: boolean) {
        await this.oauthService.logOut(noRedirectToLogin);
    }
}

