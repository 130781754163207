/// <reference types="node" />

import { BrowserModule } from '@angular/platform-browser';
import {ApplicationRef, DoBootstrap, enableProdMode, NgModule} from '@angular/core';
import {OAuthModule, OAuthStorage} from 'angular-oauth2-oidc';
import {HttpClientModule} from "@angular/common/http";
import {BrowserAnimationsModule} from "@angular/platform-browser/animations";
import { OpenIDConnectAuthService } from "services/core/authentication/oidc-auth.service";
import {LoginComponent} from "./login.component";
import {FormsModule} from "@angular/forms";
import {particlesConfig} from "../../config/particles-anim.config";
import {platformBrowserDynamic} from "@angular/platform-browser-dynamic";
import './particles.min';
import "../../styles/login/login.less";
import "../../styles/libs/font-awesome.css";
import {APP_BASE_HREF} from "@angular/common";

declare var particlesJS: any;

export function windowFactory() {
    return window;
}

@NgModule({
    imports: [BrowserModule, HttpClientModule, OAuthModule.forRoot(), BrowserAnimationsModule, FormsModule],
    declarations: [LoginComponent],
    providers: [
        { provide: OAuthStorage, useValue: localStorage },
        { provide: APP_BASE_HREF, useValue : '/' },
        { provide: 'window', useFactory: windowFactory },
        OpenIDConnectAuthService
    ]
})
export class LoginModule implements DoBootstrap {
    ngDoBootstrap(appRef: ApplicationRef) {
        particlesJS('particles-js', particlesConfig);
        appRef.bootstrap(LoginComponent);
    }
}

if (process.env.ENV === 'production') {
    enableProdMode();
}

platformBrowserDynamic()
    .bootstrapModule(LoginModule)
    .catch((err) => console.error(err));
